<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const classes = computed(() => {
    if (props.blok.primary) {
        return `justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-900`;
    } else {
        return `text-gray-900 bg-white border border-gray-200 shrink-0 focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700`;
    }
});
</script>

<template>
    <BlokLink
        :to="blok.link"
        :data-id="blok.link['data-id']"
        :data-location="blok.link['data-location']"
        :class="`inline-flex items-center py-3 px-5 text-base font-medium text-center rounded-lg focus:ring-4 hover:scale-105 duration-300 motion-reduce:transition-none motion-reduce:hover:transform-none ${classes}`"
    >
        {{ blok.text }}
    </BlokLink>
</template>
